<template>
    <div class="plants-grow">
        <div class="nav">
            <div class="top-title">
                <div class="top-title-iocn" @click="goBack"></div>
                <div class="top-title-content">{{ goodsInfo.processName }}</div>
            </div>
        </div>
        <div class="content">
            <div class="show-img">
                <div class="img-content">
                    <img class="img" :src="goodsInfo.processImg" />
                    <div class="detali">
                        <p class="word">{{ goodsInfo.processName }}</p>
                        <p class="date">{{ goodsInfo.processDate }}</p>
                    </div>
                </div>

            </div>
            <div class="tree-detail">
                <ul>
                    <li v-for="(item,index) in goodsInfo.processList" :key="index">
                        <div class="left">
                            <div class="icon">
                                <div class="dot"></div>
                            </div>
                            <div class="line" v-if="(index + 1) != goodsInfo.processList.length"></div>
                        </div>
                        <div class="right">
                            <div class="period">{{item.processName}}</div>
                            <div class="date">{{item.startTimeStr}}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { getProcessInfo } from '@/api/suyuan'
    export default {
        name: 'plantsGrow',
        data() {
            return {
                goodsInfo:{
                    processName:'',
                    processImg:'',
                    processDate:'',
                    processList:[]
                },
                processId:'',
                batchsId:''
            }
        },
        mounted(){
            //获取路由跳转的过程id
            this.processId = this.$route.params.hasOwnProperty('processId') ? this.$route.params.processId : '';
            //获取路由跳转的批次id
            this.batchsId = this.$route.params.hasOwnProperty('batchsCode') ? this.$route.params.batchsCode : '';
            //获取过程信息
            this.getProcessInfo();
        },
        methods:{
            /**
             * 导航返回事件
             */
            goBack(){
                this.$router.go(-1)
            },
            /**
             * 获取过程信息
             */
            getProcessInfo(){
                getProcessInfo({batchsId:this.batchsId,processId: this.processId}).then( res => {
                    let data = res.data;
                    this.goodsInfo.processName = data.processName;
                    this.goodsInfo.processDate = data.startTimeStr;
                    this.goodsInfo.processImg = data.imgUrl;
                    this.goodsInfo.processList = data.processList;
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .plants-grow {
        width: 100%;
        position: relative;

        .nav {
            width: 100%;
            height: 64px;
            background-color: #2D81FF;

            .top-title {
                position: fixed;
                display: flex;
                align-items: center;
                width: 100%;
                height: 64px;
                box-sizing: border-box;
                padding-top: 22px;
                padding-left: 15px;
                background-color: #2D81FF;
                z-index: 99;

                .top-title-iocn {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background: url('../../../assets/planB/back-w.png') no-repeat top center;
                    background-size: cover;
                    z-index: 2;
                }

                .top-title-content {
                    position: absolute;
                    left: calc(50% - 16px);
                    transform: translate(-50%);
                    text-align: center;
                    font-size: 20px;
                    color: #FFF;
                    z-index: 1;
                }
            }
        }

        .content {
            padding: 0 15px;
            width: 100%;
            box-sizing: border-box;

            .show-img {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                height: 82px;
                margin-top: 16px;
                border-radius: 10px;
                box-sizing: border-box;

                background: linear-gradient(310deg, #2D81FF 0%, #FFFFFF 100%);

                .img-content {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 82px;
                    padding-left: 16px;
                    background: url('../../../assets/planB/shade.png') no-repeat top center;
                    background-size: cover;

                    .img {
                        width: 50px;
                        height: 50px;
                        border-radius: 10px;
                    }

                    .detali {
                        flex: 1;
                        box-sizing: border-box;
                        padding-left: 10px;

                        .word {
                            width: 100%;
                            font-size: 20px;
                            color: #FFFFFF;
                        }

                        .date {
                            font-size: 14px;
                            color: #FFFFFF;
                        }
                    }
                }

            }

            .tree-info {
                width: 100%;
                height: 62px;
                box-sizing: border-box;
                margin-top: 15px;
                border-bottom: 1px solid #F0F0F0;

                .info {
                    width: 100%;
                    height: 28px;
                    line-height: 28px;
                    font-size: 20px;
                    color: #262626;
                }

                .date {
                    width: 100%;
                    height: 21px;
                    line-height: 21px;
                    font-size: 14px;
                    color: #8C8C8C;
                }
            }

            .tree-detail {
                width: 100%;
                padding: 12px 0;

                ul {
                    width: 100%;

                    li {
                        display: flex;
                        height: 74px;
                        position: relative;

                        .left {
                            display: flex;
                            flex-direction: column;
                            position: absolute;
                            top: 6px;

                            // padding-top: 11px;
                            .icon {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 10px;
                                height: 10px;
                                box-sizing: border-box;
                                border: 1px solid #2D81FF;
                                border-radius: 50%;

                                .dot {
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    background-color: #2D81FF;
                                }
                            }

                            .line {
                                height: 62px;
                                width: 1px;
                                border-left: 1px dotted #2D81FF;
                                margin-left: 5px;
                            }

                        }

                        .right {
                            width: 100%;
                            line-height: 24px;
                            font-size: 14px;
                            color: #8C8C8C;
                            box-sizing: border-box;
                            padding-left: 22px;

                            .period {
                                flex: 1;
                                height: 22px;
                                line-height: 22px;
                                font-size: 16px;
                                color: #262626;
                            }
                        }
                    }
                }
            }
        }

    }
</style>