import request from '@/utils/request';
//获取页面基础信息
export function getBaseInfo(data) {
    return request({
        url: 'https://apisuyuan.jimicloud.com/open/api/getBatchDetail',//请求路径
        // url: 'http://172.26.202.35:8085/api/getBatchDetail',//测试环境
        // url: 'http://localhost:8081/test/open/api/getBatchDetail',
        // target: 'http://172.26.202.35:8085/api/open/api/getBatchDetail',
        method: 'get',
        params:data
    });
}
//获取页面企业信息
export function getCompanyInfo(data) {
    return request({
        url: 'https://apisuyuan.jimicloud.com/open/api/getCompany',//请求路径
        method: 'get',
        params:data   
    });
}
//获取页面商品信息
export function getGoodsInfo(data) {
    return request({
        url: 'https://apisuyuan.jimicloud.com/open/api/getGoods',//请求路径
        method: 'get',
        params:data
    });
}
//获取商品介绍
export function getGoodsIntroduceInfo(data) {
    return request({
        url: 'https://apisuyuan.jimicloud.com/open/api/getGoodsIntroduce',//请求路径
        method: 'get',
        params:data
    });
}
//获取成长过程
export function getProcessInfo(data) {
    return request({
        url: 'https://apisuyuan.jimicloud.com/open/api/getProcess',//请求路径
        method: 'get',
        params:data
    });
}
export function getBatchsUrl(data) {
    return request({
        url: 'https://apisuyuan.jimicloud.com/open/api/getBatchsUrl',
        method: 'get',
        params: data
    })
}